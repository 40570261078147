.image {
  display: inline-flex;
  @include font-size(button);
  @include font-weight(button);
  padding: (
      (
          $button-form--height - get-line-height(button) -
            ($button-form--border-width * 2)
        ) / 2
    )
    ($button--padding-h - $button-form--border-width);
  height: $button-form--height;
  text-decoration: none !important;
  text-transform: $button--transform;
  color: color(button-light);
  background-color: color-bg(button-light);
  border-width: $button-form--border-width;
  border-style: $button-form--border-style;
  border-color: color-border(button-light);
  border-radius: $button--radius;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background 0.15s ease;

  &:active {
    outline: 0;
  }

  &:hover {
    background-color: color-bg(button-light-hover);
    border-color: color-border(button-light-hover);
  }

  &.is-loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: get-line-height(button);
      height: get-line-height(button);
      margin-left: -(get-line-height(button) / 2);
      margin-top: -(get-line-height(button) / 2);
      top: 50%;
      left: 50%;
      border: $button-loading--thickness solid color(button-light);
      border-radius: 50%;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      z-index: 1;
      animation: button-loading 0.6s infinite linear;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: color(button-disabled);
    background-color: color-bg(button-disabled) !important;
    border-color: color-border(button-disabled);

    &.is-loading {
      &::after {
        border-color: color(button-disabled);
      }
    }
  }
}

// Images
.image-full {
  width: 100%;
}

.image-larger {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + #{$image-larger--extra-width * 2});
  max-width: 100vw;
}

.image-company-icons {
  margin-left: auto;
  margin-right: auto;
  height: 160px;
  width: 160px;
}
@include media(">medium") {
  .image-top {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; //  auto|length|cover|contain|initial|inherit;
    margin-left: auto;
    margin-right: auto;
    min-height: 700px;
    max-height: 700px;
    max-width: 1920px;

    &.image-home {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/home.jpg");
    }

    &.image-newsroom {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/10_rocket_700.jpg");
    }
    &.image-jobs {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/pexels-pixabay-46168.jpg"); //pexel
    }
    &.image-projects {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/4_mars_crater.jpg"); //stocksnap
    }
    &.image-projectPpp {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/precision.JPG");
    }
    &.image-projectSatSimulator {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/simulator_facility.jpg");
    }
  }
  .carousel-img {
    max-height: 460px;
    min-width: 1000px;
    opacity: 0.5;
  }

  .image-projects-tile {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; //  auto|length|cover|contain|initial|inherit;
    margin-left: auto;
    margin-right: auto;
    min-height: 120px;
    max-height: 120px;
    max-width: 300px;
  }
}
@include media("<=medium") {
  .image-top {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; //  auto|length|cover|contain|initial|inherit;
    margin-left: auto;
    margin-right: auto;
    min-height: 300px;
    max-width: 640px;

    &.image-home {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/home.jpg");
    }
    &.image-newsroom {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/10_rocket_700.jpg");
    }
    &.image-jobs {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/pexels-pixabay-46168.jpg"); //pexel
    }
    &.image-projects {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/4_mars_crater.jpg"); //stocksnap
    }
    &.image-projectNavcast {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/precision.JPG");
    }
    &.image-projectSatSimulator {
      background-color: #fab500;
      background-image: url("./../../../images/header_images/simulator_facility.jpg");
    }
  }
  .carousel-img {
    opacity: 0.5;
  }
}


/* .img-rounded{
    border-radius: $border-radius-extreme;
    transition: opacity 0.5s ease 0s;
    max-width: 100%;
}
.img-details{
    min-height: 50px;
    padding: 0 4px 0.5em;

}
.img-details img{
    width: 50px;
}
.img-details .author{
    margin-left: 10px;
    margin-top: -21px;
    width: 40px;
}
.img-circle{
    background-color: $white-color;
    margin-bottom: 10px;
    padding: 4px;
    border-radius: 50% !important;
    max-width: 100%;
}
.img-thumbnail{
    border: 0 none;
    border-radius: $border-radius-extreme;
    box-shadow: 0 1px 2px rgba(164, 158, 147, 0.6);
    margin-bottom: 10px;
}
.img-no-padding{
    padding: 0px;
}
.example-page .img-rounded{
    margin: 50px 0 20px;
}
.img-shadow{
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12),
                0 5px 5px -3px rgba(0, 0, 0, 0.2)
}
.images-title{
    margin-bottom: 20px;
    height: 50px;
}
.nav-link .profile-photo-small{
    width: 40px;
    height: 30px;
    margin: -10px 0 0 -15px;
}
.profile-picture{
    margin: 0 auto;
    .fileinput-new img{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin-bottom: 10px;
    }
    .fileinput-exists img{
        max-width: 150px;
        max-height: 150px;
        border-radius: 50%;
        margin-bottom: 10px;
    }
}
 */
