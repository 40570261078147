.each-slide div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 500px;
  max-width: inherit;
  /*   border-radius: 15px 50px 30px 5px; */
}

.newspage-container {
  /*   border-radius: 5px; */
  background: color(thyaWhite);
}
.news-article {
  color: color(primary);
}
.news-date-author {
  color: color(low-contrast);
}

.news-abstract {
  color: color(high-contrast);
}

.news-content {
  color: color(low-contrast);
}
.news-image-caption {
  color: color(mid-contrast-inverse);
  /*   &:hover {
    color: color(high-contrast);
  } */
}

.newspage-icon {
  margin-top: 0;
  margin-bottom: 10px;
}
.newspage-image-article {
  width: cover;
}
.newspage-image-more {
  width: 500px;
}

.newspage-inner {
  background-color: color(thyaWhite);
  box-shadow:
    /* The top layer shadow */ 0 -1px 1px rgba(0, 0, 0, 0.15),
    /* The second layer */ 0 -10px 0 -5px #eee,
    /* The second layer shadow */ 0 -10px 1px -4px rgba(0, 0, 0, 0.15),
    /* The third layer */ 0 -20px 0 -10px #eee,
    /* The third layer shadow */ 0 -20px 1px -9px rgba(0, 0, 0, 0.15);
  /* Padding for demo purposes */
  padding: 30px;
}
.readmore {
  white-space: nowrap;
}
.round-border img {
  border-radius: $img-radius;
}
@include media(">medium") {
  .newsslide-text-limiter {
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  #content-desktop {
    display: block;
  }
  #content-mobile {
    display: none;
  }
}

@include media("<=medium") {
  .newsslide-text-limiter {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 0;
    -webkit-box-orient: vertical;
  }
  #content-desktop {
    display: none;
  }
  #content-mobile {
    display: block;
  }
}
