.tiles-wrap {
  &.iss-bg {
    background-image: url("../../../images/project_images/satellite_orbit_galileo_bg.svg");
    background-position: center;
    background-repeat: no-repeat;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -($tiles-items--padding__mobile / 2);
  margin-left: -($tiles-items--padding__mobile / 2);
  margin-top: -($tiles-items--padding__mobile / 2);

  &:last-of-type {
    margin-bottom: -($tiles-items--padding__mobile / 2);
  }

  &:not(:last-of-type) {
    margin-bottom: ($tiles-items--padding__mobile / 2);
  }

  &.push-left {
    &::after {
      content: "";

      flex-basis: $tiles-item--width;
      max-width: $tiles-item--width;
      box-sizing: content-box;
      padding-left: $tiles-items--padding__mobile / 2;
      padding-right: $tiles-items--padding__mobile / 2;
      height: 0;
    }
  }
}

.tiles-item {
  flex-basis: $tiles-item--width;
  max-width: $tiles-item--width;
  box-sizing: content-box;
  padding: $tiles-items--padding__mobile / 2;

  * {
    box-sizing: border-box;
  }
}

.tiles-item-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  padding: $tiles-item--inner-padding-v $tiles-item--inner-padding-h;
}

@if ($tiles-items--padding__desktop != null) {
  @include media(">medium") {
    .tiles-wrap {
      margin-right: -($tiles-items--padding__desktop / 2);
      margin-left: -($tiles-items--padding__desktop / 2);
      margin-top: -($tiles-items--padding__desktop / 2);

      &:last-of-type {
        margin-bottom: -($tiles-items--padding__desktop / 2);
      }

      &:not(:last-of-type) {
        margin-bottom: ($tiles-items--padding__desktop / 2);
      }

      &.push-left {
        &::after {
          padding-left: $tiles-items--padding__desktop / 2;
          padding-right: $tiles-items--padding__desktop / 2;
        }
      }
    }

    .tiles-item {
      padding: $tiles-items--padding__desktop / 2;
    }
  }
}
