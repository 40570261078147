.container,
.container-sm,
.container-xsm,
.container-xs {
  width: 100%;
  margin: 0 auto;
  padding-left: $container--padding__mobile;
  padding-right: $container--padding__mobile;

  @include media(">small") {
    padding-left: $container--padding__desktop;
    padding-right: $container--padding__desktop;
  }
}

.container {
  max-width: $container--width + ($container--padding__desktop * 2);
}

.container-sm {
  max-width: $container--width-sm + ($container--padding__desktop * 2);
}

.container-xsm {
  max-width: $container--width-xsm + ($container--padding__desktop * 2);
}

.container-xs {
  max-width: $container--width-xs + ($container--padding__desktop * 2);
}

.container-top-box {
  background-color: rgba(color-bg(body), 0.6);
  margin-top: 120px;
  padding-top: 20px;
  padding-bottom: 30px;
  /*   clip-path: polygon(
    0% 40px,
    100px 0%,
    calc(100% - 0px) 0%,
    100% 0px,
    100% calc(100% - 40px),
    calc(100% - 100px) 100%,
    0px 100%,
    0 calc(100% - 0px)
  ); 
  -webkit-clip-path: circle(60% at 50% 10%);
  clip-path: polygon(
    83% 0,
    80% 5%,
    0 5%,
    0 100%,
    28% 100%,
    31% 95%,
    100% 95%,
    100% 5%,
    100% 0
  );*/
}

[class*="container"] {
  [class*="container"] {
    padding-left: 0;
    padding-right: 0;
  }

  .container-sm {
    max-width: $container--width-sm;
  }

  .container-xsm {
    max-width: $container--width-xsm;
  }

  .container-xs {
    max-width: $container--width-xs;
  }
}
/* 
.clip-lower-left {
  clip-path: polygon(100% 0, 100% 100%, 20% 100%, 0 90%, 0 0);
}
.clip-upper-right {
  clip-path: polygon(100% 10%, 100% 100%, 0 100%, 0 0, 80% 0);
}
 */
/* .clip-folder {
  clip-path: polygon(
    63% 0,
    60% 7%,
    0 7%,
    0 100%,
    40% 100%,
    43% 93%,
    100% 93%,
    100% 7%,
    100% 0
  );
} */

.clip-folder {
  clip-path: polygon(
    0 0,
    25% 0,
    27% 5%,
    50% 5%,
    52% 0%,
    98% 0,
    100% 5%,
    100% 95%,
    27% 95%,
    25% 100%,
    2% 100%,
    0 95%
  );
}

.clip-folder-home {
  clip-path: polygon(
    0 0,
    25% 0,
    27% 4%,
    50% 4%,
    52% 0%,
    98% 0,
    100% 4%,
    100% 96%,
    27% 96%,
    25% 100%,
    2% 100%,
    0 96%
  );
}

.clip-leftagon {
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 100%,
    0 70%,
    2% 65%,
    2% 35%,
    0 30%
  );
}

.clip-rightagon {
  clip-path: polygon(
    100% 0,
    100% 30%,
    98% 35%,
    98% 65%,
    100% 70%,
    100% 100%,
    0 100%,
    0 0
  );
}

.clip-topbottom {
  clip-path: polygon(
    60% 0,
    59% 4%,
    41% 4%,
    40% 0,
    0 0,
    0 100%,
    40% 100%,
    41% 96%,
    59% 96%,
    60% 100%,
    100% 100%,
    100% 0
  );
}

.clip-news {
  clip-path: polygon(
    0 0,
    25% 0,
    27% 5%,
    50% 5%,
    52% 0%,
    98% 0,
    100% 5%,
    100% 95%,
    27% 95%,
    25% 100%,
    2% 100%,
    0 95%
  );
}

.clip-top-image {
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    90% 100%,
    88% 98%,
    12% 98%,
    10% 100%,
    0 100%
  );
}
