.table-content {
  .table-hover thead tr:hover th,
  .table-hover tbody tr:hover td {
    color: color(primary);
    cursor: pointer;
  }
  a:not(.button) {
    color: white;
    outline: 0;
    .invert-color & {
      @include anchor-aspect(header, inverse);
    }
    &:hover {
      color: color(primary);
    }
  }
}
