.newsteaser {
  .section-inner {
    padding-top: $newsteaser--padding-t__mobile;
    padding-bottom: $newsteaser--padding-b__mobile;
  }

  .section-header {
    padding-bottom: $newsteaser-header--padding__mobile;
  }

  .tiles-wrap {
    @if ($newsteaser-items--padding__mobile != null) {
      margin-right: -($newsteaser-items--padding__mobile / 2);
      margin-left: -($newsteaser-items--padding__mobile / 2);
      margin-top: -($newsteaser-items--padding__mobile / 2);

      &:last-of-type {
        margin-bottom: -($newsteaser-items--padding__mobile / 2);
      }

      &:not(:last-of-type) {
        margin-bottom: ($newsteaser-items--padding__mobile / 2);
      }
    }

    &.push-left {
      &::after {
        flex-basis: $newsteaser-item--width;
        max-width: $newsteaser-item--width;
        @if ($newsteaser-items--padding__mobile != null) {
          padding-left: $newsteaser-items--padding__mobile / 2;
          padding-right: $newsteaser-items--padding__mobile / 2;
        }
      }
    }
  }

  .tiles-item {
    flex-basis: $newsteaser-item--width;
    max-width: $newsteaser-item--width;
    @if ($newsteaser-items--padding__mobile != null) {
      padding: $newsteaser-items--padding__mobile / 2;
    }
  }

  .tiles-item-inner {
    background-color: color(high-contrast);
    opacity: 0.8;
    padding-top: $newsteaser-item--inner-padding-v;
    padding-bottom: $newsteaser-item--inner-padding-v;
    padding-left: $newsteaser-item--inner-padding-h;
    padding-right: $newsteaser-item--inner-padding-h;
  }
}

.newsteaser-item-content {
  flex-grow: 1;
}

.newsteaser-item-source {
  display: inline-flex; // to allow centering when using .content-center on a parent tag
  flex-wrap: wrap;
  align-items: center;
}

@include media(">medium") {
  .newsteaser {
    .section-inner {
      padding-top: $newsteaser--padding-t__desktop;
      padding-bottom: $newsteaser--padding-b__desktop;
    }

    .section-header {
      padding-bottom: $newsteaser-header--padding__desktop;
    }

    @if ($newsteaser-items--padding__desktop != null) {
      .tiles-wrap {
        margin-right: -($newsteaser-items--padding__desktop / 2);
        margin-left: -($newsteaser-items--padding__desktop / 2);
        margin-top: -($newsteaser-items--padding__desktop / 2);

        &:last-of-type {
          margin-bottom: -($newsteaser-items--padding__desktop / 2);
        }

        &:not(:last-of-type) {
          margin-bottom: ($newsteaser-items--padding__desktop / 2);
        }

        &.push-left {
          &::after {
            padding-left: $newsteaser-items--padding__desktop / 2;
            padding-right: $newsteaser-items--padding__desktop / 2;
          }
        }
      }

      .tiles-item {
        padding: $newsteaser-items--padding__desktop / 2;
      }
    }
  }
}
